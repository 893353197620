import React, {useState, useEffect} from "react";
import moment from "moment";
import {useFieldArray} from "react-hook-form";
import {format} from 'date-fns';

const MeasurementsItems = (props) => {
    const [dataIndex, setDataIndex] = useState(false);
    const [which, setWhich] = useState(null);
    const control = props.control;
    const register = props.register;
    useEffect(() => {
    }, [dataIndex])
    useEffect(() => {
        if (which === props.which && props && props.removeIndex !== null) remove(props.removeIndex);
    }, [props.removeIndex, props.refreshRemoveIndex])
    useEffect(() => {
        setWhich(props.which);
        (which === props.which && props.appendItem !== null && props.appendItem !== undefined) && addValue();
    }, [props.appendItem])
    //(props && props.appendItem !==null && props.appendItem !==undefined && props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure]`).length === 0 && append(props.appendItem))
    const {fields, remove, append} = useFieldArray({
        control,
        name: `process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`,
        key: "insertedDate"
    });
    useEffect(() => {
    }, [props.reloader])
    useEffect(() => {
        if (props.editIndex) {
            fields[props.editIndex.index].insertedDate = props.editIndex.insertionDate;
            //props.setValue(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure][${props.editIndex.index}].insertedDate`,props.editIndex.insertionDate)
            props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`).sort(compareDatesAsc);
            //fields.sort(compareDatesAsc);
        }
    }, [props.refresh])
    const startAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd HH:mm:00.000');
        return new Date(parsed);
    };
    const addValue = async () => {
        let fieldComment = await props.db.collection('measurement').doc(props.getValues(`id`)).collection('process').doc(props.getValues(`process[${props.nestIndex}].id`)).collection(`${props.which}Comments`)
            .where("insertedDate", ">=", startAt(props.appendItem.insertedDate))
            .where("insertedDate", "<", startAt((new Date(props.appendItem.insertedDate)).setMinutes((new Date(props.appendItem.insertedDate)).getMinutes() + 1)))
            .limit(1).get();
        //console.log('fieldMeasure`)',fieldComment.docs.length);
        let same = props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`).find((z) => z && z.insertedDate && moment(z.id === undefined ? z.insertedDate : z.insertedDate.toDate()).isSame(props.appendItem.insertedDate, 'minute'));
        //console.log('same', same);
        if (fieldComment.docs.length === 0 && same === undefined) append(props.appendItem);
        else return
        /*let index =props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`).length
        props.setValue(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure][${index}]`,props.appendItem)*/
    }

    // Define your custom comparator function to sort by datetime
    const dateFormatter = (date) => {
        const format = 'YYYY/MM/DD HH:mm';
        if (moment(date).isValid()) {
            return moment(date).format(format);
        }
        return moment(date.toDate()).format(format);
    }

    function compareDatesAsc(a, b) {
        const dateA = dateFormatter(a.insertedDate);
        const dateB = dateFormatter(b.insertedDate);
        if (dateA < dateB) {
            return -1;
        }
        if (dateA > dateB) {
            return 1;
        }
        return 0;
    }

    props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`).sort(compareDatesAsc);
    //fields.sort(compareDatesAsc);
    //console.log('sortedFields',fields);
    //console.log('getValuesITem', props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`));
    const handleToleranceColor = (value, lvlMin, lvlMax, rangeSettings) => {
        //console.log('value', value,lvlMin,lvlMax);
        let color = '';
        switch (rangeSettings) {
            case '上限下限共':
                color = (value * 1 >= lvlMin * 1 && value * 1 <= lvlMax * 1 ? '' : '#FF0000');
                break;
            case '下限のみ':
                color = (value * 1 >= lvlMin * 1 ? '' : '#FF0000');
                break;
            case '上限のみ':
                color = (value * 1 <= lvlMax * 1 ? '' : '#FF0000');
                break;
            default:
                color = (value * 1 >= lvlMin * 1 && value * 1 <= lvlMax * 1 ? '' : '#FF0000');
                break;
        }
        return color;
    }

    return (
        props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`).map((vl, vl_index) => (
            <td key={vl.insertedDate} style={{textAlign: 'center', alignItems: 'center'}}
                //hidden={moment(vl.insertedDate.seconds === undefined ? new Date() : vl.insertedDate.toDate()).format('YYYY/MM/DD') >= moment(props.dateFormatter(new Date())).subtract(props.searchChart, 'months').format('YYYY/MM/DD') ? false : true}
            >
                <input key={vl.insertedDate} step={'any'} className={'form-control'} autoFocus={false} style={{
                    width: 60 + (vl.value.length === undefined ? 0 : vl.value.length > 4 ? vl.value.length : 0) * 5 + 'px',
                    borderWidth: !props.editClicked ? '0' : 'thin',
                    textAlign: 'center',
                    alignItems: 'center',
                    backgroundColor: !props.editClicked ? 'white' : (vl.edit ? '#FFFDE8' : ''),
                    color: handleToleranceColor(props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure][${vl_index}].value`), props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}].tolerance_lvl_min`), props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}].tolerance_lvl_max`), props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}].range_settings`))
                }} disabled={!props.editClicked} type="number"
                       {...register(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure][${vl_index}].value`)}
                       onChange={(e) => {
                           props.setValue(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure][${vl_index}]`, {
                               value: e.target.value,
                               edit: vl.id === undefined ? false : true,
                               insertedDate: vl.insertedDate,
                               id: vl.id,
                               changed: true,
                           })
                           setDataIndex(!dataIndex);
                       }}
                />
            </td> //: <td>dd</td>
        ))
    )
}

export default MeasurementsItems;
